<template>
  <div class="pb-2">
    <portal to="body-top">
      <customer-name title="" />
    </portal>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :create-page-url="{ name: 'home-customers-addresses-create' }"
      :table-config-options="tableConfigOptions()"
      back-to-list-path="home-customers-addresses-list"
    >
      <template #cell(actions)="{ data }">
        <div class="text-nowrap">
          <feather-icon
            icon="LTrashIcon"
            size="30"
            style="padding: 2px 6px"
            class="cursor-pointer mr-1 border-dotted defaultIconColor"
            @click="remove(data.item)"
          />
          <feather-icon
            icon="LLoadIcon"
            size="30"
            style="padding: 4px"
            class="cursor-pointer mr-1 border-dotted defaultIconColor"
            @click="restoreAddress(data.item)"
          />
        </div>
      </template>
      <template #cell(state)="{ data }">
        {{ data.value.name }}
      </template>
      <template #cell(type)="{ data }">
        {{ getTypeText(data.item) }}
      </template>
      <template #filter>
        <span />
      </template>
    </l-table>
  </div>
</template>

<script>

import LTable from '@/views/components/LTable/LTable.vue'
import ButtonDashed from '@/@core/components/b-button-dashed/ButtonDashed.vue'
import CustomerName from '../../../components/CustomerName.vue'
import config from '../addressConfig'

export default {
  name: 'AddressTrashList',
  components: {
    LTable,
    CustomerName,
    ButtonDashed,
  },
  data() {
    return {
      isLoadingOfSetDisabled: false,
      idOfSettledDefault: null,
    }
  },
  computed: {
    // tableConfigOptions() {
    //   return {
    //     data: {
    //       order_type: 'desc',
    //     },
    //   }
    // },
  },
  methods: {
    tableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/getInActiveAddressList`,
      }
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    remove(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/del`)
        .then(() => {
          this.refetchData()
        })
    },
    restoreAddress(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/setActive`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
        .then(() => {
          this.refetchData()
        })
    },
    update(data) {
      this.$router.push({
        name: 'home-customers-addresses-update',
        params: { address: data.id },
      })
    },
    getTableConfigOptions() {
      return {
        getData: response => response.data.data,
        getTotal: () => 0,
      }
    },
    isSettling(id) {
      return this.isLoadingOfSetDisabled && this.idOfSettledDefault === id
    },
    getTypeText(data) {
      const arr = []
      if (data.billing) arr.push('Billing')
      if (data.shipping) arr.push('Shipping')
      if (data.account_holder) arr.push('Account Holder')
      return arr.join(',')
    },
    setDefault(data) {
      const {
        item: { id },
      } = data
      this.isLoadingOfSetDisabled = true
      this.idOfSettledDefault = id
      this.sendNotification(
        this,
        {
          id,
        },
        `${this.MODULE_NAME}/setDefault`,
      )
        .then(() => {
          this.refetchData()
          this.isLoadingOfSetDisabled = false
        })
    },
  },
  setup() {
    const MODULE_NAME = 'address'
    const { tableColumns } = config()
    return {
      tableColumns,
      MODULE_NAME,
    }
  },
}
</script>
